import styled from "styled-components"

export const ContactFormWrapper = styled.div`
  font-family: ${({ theme }) => theme.fonts[0]};
  font-weight: 800;
  font-size: 1rem;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};

  @media (min-width: 992px) {
    padding-left: 25%;
    align-items: flex-start;
  }

  h3 {
    margin-left: 100px;
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fonts[0]};
  }
`

export const FormControl = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  @media (min-width: 992px) {
    justify-content: flex-start;
  }
`

export const InputLabel = styled.label`
  padding: 1rem;
  width: 110px;
`

export const FormInput = styled.input`
  border-radius: 4px;
  width: 300px;
  padding: 10px 12px;
  outline: none;
  border: none;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  box-shadow: 0 4px 8px 0 rgba(21, 21, 21, 0.2);
`
export const FormTextArea = styled.textarea`
  border-radius: 4px;
  width: 300px;
  height: 150px;
  padding: 10px 12px;
  outline: none;
  border: none;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  box-shadow: 0 4px 8px 0 rgba(21, 21, 21, 0.2);
`
export const FormButton = styled.button`
  font-family: ${({ theme }) => theme.fonts[0]};
  font-size: 1.1em;
  margin-top: 10px;
  padding: 14px 130px 8px;
  background-color: ${({ theme }) => theme.colors.positive1};
  color: ${({ theme }) => theme.colors.black};
  font-weight: bold;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 8px 20px -6px rgba(black, 0.3);
  @media (min-width: 992px) {
    padding: 14px 40px 8px;
  }
`
