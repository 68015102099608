import axios from "axios"
import React, { useCallback, useState } from "react"
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3"
import {
  ContactFormWrapper,
  FormButton,
  FormControl,
  FormInput,
  FormTextArea,
  InputLabel,
} from "./ContactUsForm.styles"

export const ContactUsForm = () => {
  const [token, setToken] = useState("")

  const verifyRecaptcha = useCallback(token => {
    console.log(token)

    setToken(token)
  }, [])

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      form.reset()
    }
  }
  const handleOnSubmit = e => {
    e.preventDefault()
    const form = e.target
    setServerState({ submitting: true, status: null })
    const data = new FormData(form)

    data.append("g-recaptcha-response", token)

    axios({
      method: "post",
      url: process.env.GET_FORM_URL,
      data,
    })
      .then(r => {
        handleServerResponse(true, "Thanks!", form)
      })
      .catch(r => {
        handleServerResponse(false, r, form)
      })
  }

  return (
    <ContactFormWrapper>
      <h3>Contact Us</h3>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.GOOGLE_RECAPTCHA_SITE_KEY}
      >
        <form onSubmit={handleOnSubmit}>
          <FormControl>
            <InputLabel>Name</InputLabel>
            <FormInput type="text" name="name" placeholder="Your Name" />
          </FormControl>
          <FormControl>
            <InputLabel>Email</InputLabel>
            <FormInput type="email" name="email" placeholder="Your Email" />
          </FormControl>
          <FormControl>
            <InputLabel>Subject</InputLabel>
            <FormInput type="text" name="subject" placeholder="Subject" />
          </FormControl>
          <FormControl>
            <InputLabel>Message</InputLabel>
            <FormTextArea name="message" placeholder="Message" />
          </FormControl>
          <FormControl>
            <InputLabel />
            <FormButton type="submit">Send</FormButton>
          </FormControl>
          <GoogleReCaptcha onVerify={verifyRecaptcha} />
        </form>
      </GoogleReCaptchaProvider>
    </ContactFormWrapper>
  )
}
