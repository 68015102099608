import React from "react"
import { ContactUsForm } from "../components/ContactUsForm"
import { Layout } from "../components/Layout"

const ContactUs = () => {
  return (
    <Layout>
      <ContactUsForm />
    </Layout>
  )
}

export default ContactUs
